@import '../../assets/styles/utility/variables';

.rounded-card {
  &__container {
    background: $color-white;
    border-bottom: 1px solid $color-gray-2;
    border-left: 1px solid $color-gray-2;
    border-radius: 12px;
    border-right: 1px solid $color-gray-2;
    border-top: 16px solid $color-blue-4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
    width: 100%;

    .external-link:hover {
      color: $color-blue-2;

      svg > path {
        fill: $color-blue-2;
      }
    }

    @media only screen and (max-width: $mobile-screen) {
      margin: 0 0 50px 0;
      padding: 25px 30px;
      width: auto;
    }

    &--primary {
      border-top: 16px solid $color-blue-3;

      .rounded-card__tag {
        background: $color-blue-3;
      }
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;

    img {
      height: 50px;
      align-self: center;
    }
  }

  &__tag {
    background: $color-blue-4;
    color: $color-white;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 4px;
    width: fit-content;
  }

  &__description {
    margin: 24px 0;
  }
}
