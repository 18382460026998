@import '../../assets/styles/utility/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #002f6c;
  height: $nav-height;

  &__nav {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;

    @include media-up(md) {
      padding-left: 0;
    }

    li {
      margin-right: 48px;

      > a {
        font-weight: normal;
        font-size: 14px;
        line-height: 66px;
        letter-spacing: 0.1428em;
        text-transform: uppercase;
        color: #ffffff;
        text-decoration: none;

        &:hover {
          color: $color-tag-thematic;
        }
      }
    }

    li:last-of-type {
      margin-right: 0px;
    }
  }

  .selected-link {
    font-weight: bold;
  }

  @include media-down(md) {
    &__nav {
      padding: 32px 0;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 81px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      width: 100%;
      height: 720px;
      background: url(../../assets/images/photo-mobile-menu-01.png);
      background-size: cover;
      background-position: center;

      li {
        padding: 32px 0;
        margin-right: 0px;
      }
    }

    &__nav.active {
      box-shadow: inset 0 0 0 100vmax rgba(4, 61, 118, 0.6);
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 5;
    }
  }
}

.nav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-wrapper--logo-only {
  justify-content: flex-end;
}

.nav-logo {
  display: block;
  max-width: 100%;
}
