@import '../../assets/styles/utility/variables';

.footer {
  padding: remCalc(30px) 0;
  background-color: $color-obsidian;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: $mobile-screen) {
    padding: 60px 24px;
  }
}

.footer-wrapper {
  display: flex;
  flex-direction: column-reverse;

  @include media-up(md) {
    flex-direction: row;
    justify-content: center;
  }
}

.footer-disclaimer {
  color: $color-white;
  margin: 0 0 remCalc(100px) 0;

  @include media-up(md) {
    margin: 0 0 0 remCalc(300px);
  }
}

.footer-logo {
  max-width: 100px;
}
