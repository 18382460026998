@import '../../assets/styles/utility/variables';
@import './SearchContainer';

.search-input {
  &__form {
    @extend .search-input;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px 19px 19px;
    line-height: normal;
  }

  input {
    border: transparent;
    border-radius: 8px;
    color: #757575;
    font-family: $font-family;
    font-size: 16px;
    height: auto;
    outline: none;
    overflow: hidden;
    padding: 19px 16px 19px !important;
    resize: none;
    width: 95%;
    word-break: break-word;
    @include media-down(xl) {
      height: auto;
    }
  }

  &.input_focus {
    box-shadow: 0 0 0 1px #2684ff;
  }

  &__icon {
    fill: $color-gray-4;
    height: 17.5px;
    width: 17.5px;
  }

  @include media-down(xl) {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: $font-family;
      font-size: 16px;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-family: $font-family;
      font-size: 16px;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-family: $font-family;
      font-size: 16px;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-family: $font-family;
      font-size: 16px;
    }
  }
}
