@import '../../assets/styles/utility/variables';

$slider-width: 39px;
$toggle-width: 18px;
$transform-x: 15px;

.toggle-switch {
  column-gap: 12px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.toggle {
  background: transparent;
  border: 0;
  color: $color-black;
  height: 22px;
  padding: 0;
  position: relative;
}

.toggle_slider {
  -webkit-transition: 0.4s;
  background-color: $color-gray-6;
  border-radius: 15px;
  bottom: 0;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  transition: 0.4s;
  width: $slider-width;
}

.toggle_slider:before {
  -webkit-transition: 0.4s;
  background-color: $color-gray-5;
  border-radius: 50%;
  bottom: 2px;
  content: '';
  height: $toggle-width;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: $toggle-width;
}

.toggled .toggle_slider {
  background-color: rgba(0, 87, 200, 0.3);
}

.toggled .toggle_slider:before {
  -webkit-transform: translateX($transform-x);
  -ms-transform: translateX($transform-x);
  background-color: $color-secondary-blue;
  transform: translateX($transform-x);
}
