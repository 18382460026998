@import '../../assets/styles/utility/variables';

.photocard {
  &__container {
    display: flex;
    flex-direction: column;
    width: 45%;

    img {
      margin-bottom: remCalc(17px);
    }

    .external-link:hover {
      color: $color-blue-2;

      svg > path {
        fill: $color-blue-2;
      }
    }

    @include media-down(lg) {
      width: 100%;
      margin-bottom: remCalc(56px);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: $color-secondary-blue;
  }

  &__description {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }
}
