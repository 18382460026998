@import '../../assets/styles/utility/variables';

@mixin masthead-banner($image) {
  @include media-up(md) {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      background: url($image);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.banner {
  &__container {
    min-height: 478px;
    width: 100%;

    @include media-up(md) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }

    @media only screen and (max-width: $mobile-screen) {
      min-height: 0;
    }
  }

  &__home {
    @include masthead-img('../../assets/images/photo-home-01.png');
    @include media-up(md) {
      &::before {
        background: linear-gradient(90.53deg, #103b74 54.49%, rgba(0, 62, 120, 0) 79.93%),
          linear-gradient(90.53deg, #0a63d7 54.49%, rgba(0, 47, 108, 0) 79.93%);
        mix-blend-mode: normal;
      }
    }
  }

  &__services {
    @include masthead-banner('../../assets/images/services/heroimage-04.svg');
  }

  &__partners {
    @include masthead-banner('../../assets/images/partners_banner.png');
    @include media-up(md) {
      &::before {
        background: linear-gradient(90.53deg, $color-dark-blue 54.49%, rgba(0, 62, 120, 0) 79.93%),
          linear-gradient(90.53deg, transparent 54.49%, rgba(0, 47, 108, 0) 79.93%);
        mix-blend-mode: normal;
      }
    }
  }

  &__our-people {
    @include masthead-img('../../assets/images/pexels-thirdman-5256816.jpg');
    @include media-up(md) {
      &::before {
        background: linear-gradient(90deg, #1b3e54 54.49%, rgba(27, 62, 84, 0) 70%);
        mix-blend-mode: normal;
      }
    }
  }

  &__text-container {
    padding: remCalc(24px) 0;

    @include media-up(md) {
      padding: 95px 0;
    }
  }

  &__image {
    @media only screen and (max-width: $mobile-screen) {
      width: 100%;
    }
  }
}

.banner-wrapper {
  position: relative;
  z-index: 2;
}
