@import '../../assets/styles/utility/variables';

.search-input {
  background-color: $color-white !important;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  box-sizing: border-box;
  min-height: 38px !important;
}

.search-container {
  margin-bottom: 40px;
  min-height: 60px;
  position: relative;
}

.search-filter-container {
  width: 100%;

  @include media-down(lg) {
    display: none;
  }
}

.search-filters-remove {
  color: $color-remove-button !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0 !important;
  background: none !important;
  border: none;
  margin-left: 5px;
}

.search-filter-container-mobile {
  display: none;
  @include media-down(lg) {
    display: block;
    flex-direction: column;
    position: relative;
  }
}

.search-result-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 50px 0 80px;
  max-height: remCalc(1212px);
  overflow-y: scroll;
  row-gap: 32px;

  @include media-down(lg) {
    max-height: remCalc(2398px);
  }
}

.search-tags-container {
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  row-gap: 20px;
}

.search-filter-tag {
  align-items: center;
  background: $color-tag-default;
  border-radius: 10px;
  color: $color-black;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 2px 15px;
  text-align: center;

  @include media-down(lg) {
    text-align: left;
  }

  &--region {
    background: $color-tag-region;
    &.results-tag:before {
      @include media-up(lg) {
        content: 'Region:\00a0';
      }
    }
  }

  &--country {
    background: $color-tag-country;
    &.results-tag:before {
      @include media-up(lg) {
        content: 'Country:\00a0';
      }
    }
  }

  &--sector {
    background: $color-tag-sector;
    &.results-tag:before {
      @include media-up(lg) {
        content: 'Sector:\00a0';
      }
    }
  }

  &--thematic {
    background: $color-tag-thematic;
    &.results-tag:before {
      @include media-up(lg) {
        content: 'Thematic Area:\00a0';
      }
    }
  }

  &--technical {
    background: $color-tag-technical;
    &.results-tag:before {
      @include media-up(lg) {
        content: 'Technical Area:\00a0';
      }
    }
  }

  &.results-tag {
    border-radius: 30px;
    padding: 8px 15px;
  }

  &--close-icon {
    background-color: rgba(0, 87, 200, 0.21);
    border-radius: 50%;
    box-sizing: content-box;
    height: 10px;
    padding: 5.5px;
    width: 10px;
    margin-left: 14px;

    path {
      fill: rgba(0, 87, 200, 0.21);
      stroke: $color-secondary-blue;
    }
  }
}
