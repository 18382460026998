@import '../../assets/styles/utility/variables';
@import './SearchContainer';

.search-filter {
  position: relative;
  width: 100%;

  &_indicator-container {
    padding: 23px 20px 21px 0;
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__menu {
    background: $color-white;
    border-radius: 10px;
    box-shadow: 4px 4px 16px 8px rgba(0, 0, 0, 0.25);
    color: $color-gray;
    height: auto;
    padding: 0 12px;
    position: absolute;
    width: 326px;
    z-index: 100;

    @include media-down(lg) {
      width: 100%;
    }
  }

  &__menu-title {
    color: $color-black;
    font-weight: 600;
    padding: 12px;
  }

  &__options-list-wrapper {
    padding: 0 13px 27px;
  }

  &__button-wrapper {
    border-top: 1px solid rgba(0, 28, 128, 0.3);
    display: flex;
    justify-content: space-between;
    padding: 17px 11px;
    z-index: 100;

    button {
      z-index: 100;
    }
  }

  &__option {
    padding: 8px 0;
    z-index: 100;

    label {
      color: $color-gray;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding-left: 8px;
    }
  }
}

// react-select override
.usaid-search__control {
  @extend .search-input;
}

.usaid-search__input {
  color: #757575 !important;
  font-family: $font-family !important;
  font-size: 16px !important;
}

.usaid-search__value-container {
  padding: 19px 16px !important;
}

.usaid-search__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.usaid-search__menu-list--is-multi {
  @include media-down(lg) {
    max-height: 240px;
  }
}
