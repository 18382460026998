@import '../../assets/styles/utility/variables';

.external-link {
  align-items: center;
  column-gap: 7px;
  display: flex;
  flex-direction: row;

  svg {
    height: 18px;
    width: 18px;
  }
}
