@import '../../assets/styles/utility/variables';

.service-card {
  border-radius: 10px;
  box-shadow: 4px 4px 16px 8px rgba(31, 68, 140, 0.08);
  max-width: remCalc(530px);
  min-height: remCalc(556px);

  @include media-down(xl) {
    height: auto;
    width: 100%;
  }

  h6 {
    margin: 0 0 remCalc(15px);
    min-height: remCalc(45px);
  }

  ul {
    margin-block-start: 0;
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: remCalc(39px) remCalc(24px);
  }

  &_inner {
    margin-top: remCalc(20px);
  }

  &__image {
    height: remCalc(306px);
    width: 100%;

    @include media-down(sm) {
      height: remCalc(221px);
    }
  }

  &__learn-more,
  &__see-less {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .service-card__learn-more-button:hover,
    .button-link:hover {
      color: $color-blue-2;

      img {
        filter: invert(60%) sepia(5%) saturate(4072%) hue-rotate(206deg) brightness(40%)
          contrast(98%);
      }
    }
  }

  &__learn-more {
    margin-top: remCalc(44px);
  }

  &__learn-more-button {
    height: remCalc(24px);
    position: relative;
    text-align: left;
    width: 100%;
  }

  &__see-less {
    margin-top: remCalc(18px);
  }

  &__icon {
    height: remCalc(24px);
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: remCalc(24px);
  }
}
