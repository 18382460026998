@import '../../assets/styles/utility/variables';

.hamburger {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  border: none;
  cursor: pointer;

  &__line {
    width: 20px;
    height: 3px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    background: #ffffff;
  }

  &__line::before,
  &__line::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    background: #ffffff;
  }

  &__line::before {
    transform: translateY(-6px);
  }

  &__line::after {
    transform: translateY(6px);
  }

  .open &__line {
    transform: translateX(-50px);
    background: transparent;
  }

  .open &__line::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .open &__line::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  @include media-down(md) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    transition: all 0.5s ease-in-out;
    border: none;
    cursor: pointer;
  }
}

.hamburger-wrapper {
  display: none;

  @include media-down(md) {
    display: block;
  }
}
