@import './variables';

// flex utils
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include media-down(lg) {
    flex-direction: column;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &--reverse {
    @include media-down(sm) {
      flex-direction: column-reverse;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex__justify-start {
  justify-content: flex-start;
}

.flex-row-gap-60-24 {
  row-gap: remCalc(60px);

  @include media-down(sm) {
    row-gap: remCalc(24px);
  }
}

.flex-row-gap-20 {
  row-gap: remCalc(20px);
}

.flex-col-gap-20 {
  column-gap: remCalc(20px);
}

.flex-col-gap-24 {
  column-gap: remCalc(24px);
}

.flex-gap-36-16 {
  @include media-up(sm) {
    column-gap: remCalc(36px);
    row-gap: remCalc(36px);
  }
  row-gap: remCalc(16px);
}

.grid {
  display: grid;
}

//CUSTOM SPACING CLASSES
.margin-bottom-60 {
  margin-bottom: remCalc(60px) !important;
}

.margin-bottom-40 {
  margin-bottom: remCalc(40px) !important;
}

.margin-bottom-30 {
  margin-bottom: remCalc(30px) !important;
}

.margin-bottom-12 {
  margin-bottom: remCalc(12px) !important;
}

.margin-top-60 {
  margin-top: remCalc(60px) !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}
.padding-top-90 {
  padding-top: remCalc(90px) !important;
}

.padding-top-70 {
  padding-top: remCalc(70px) !important;
}

.padding-top-50 {
  padding-top: remCalc(50px) !important;
}

.padding-top-40 {
  padding-top: remCalc(40px) !important;
}

.padding-top-30 {
  padding-top: remCalc(30px) !important;
}

.padding-top-20 {
  padding-top: remCalc(20px) !important;
}

.padding-bottom-70 {
  padding-bottom: remCalc(70px) !important;

  @include media-down(sm) {
    padding-bottom: remCalc(50px) !important;
  }
}
.padding-bottom-90 {
  padding-bottom: remCalc(90px) !important;
}

.padding-bottom-30 {
  padding-bottom: remCalc(30px) !important;
}

.padding-bottom-20 {
  padding-bottom: remCalc(20px) !important;
}

// display utils
.d-mobile-none {
  @include media-down(sm) {
    display: none;
  }
}

.d-mobile-only {
  display: none;
  @include media-down(sm) {
    display: block;
  }
}

.width-60p {
  width: 60%;
  @include media-down(sm) {
    width: auto;
  }
}

// position
.p-realtive {
  position: relative;
}
