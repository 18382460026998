@import '../../assets/styles/utility/variables';

.constultant-card {
  border-radius: 10px;
  border: 0.5px solid #5e5e5e;
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: remCalc(596px);

  @include media-down(xl) {
    max-width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: remCalc(24px) remCalc(24px) 0 remCalc(24px);
  }

  &__heading {
    column-gap: remCalc(24px);
    display: flex;
    flex-direction: row;
    justify-content: start;

    @include media-down(lg) {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__profile-picture {
    border-radius: 50%;
    height: remCalc(92px);
    object-fit: cover;
    width: remCalc(92px);
  }

  &__body {
    padding-bottom: remCalc(15px);
    padding-top: remCalc(23px);
  }

  &__footer {
    align-items: center;
    border-top: 0.5px solid #bbd3e0;
    column-gap: 7px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px 0 17px;

    .external-link:hover {
      color: $color-blue-2;

      svg > path {
        fill: $color-blue-2;
      }
    }
  }
}
