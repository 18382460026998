@import './utility/variables';
@import './utility/utils';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  border: 0;
  color: $font-color-primary;
  font-family: $font-family;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

header,
main,
footer {
  margin: 0 auto;

  @include media-down(sm) {
    margin: 0;
    padding: 0;
  }
}
a {
  cursor: pointer;
}

.section {
  background: #f7feff;

  @include media-down(sm) {
    padding: 0 24px;
  }

  &--light-blue {
    background-color: $color-blue-light;
  }

  &--blue {
    background-color: $color-blue-2;
    color: $color-white;
  }

  &--secondary-blue {
    background-color: $color-secondary-blue;
  }

  &--dark-blue {
    background-color: $color-dark-blue;
    color: $color-white;
  }

  &__standard {
    background: $color-white;

    // TODO revisit mobile styles
    @include media-down(sm) {
      padding: 0 24px;
    }
  }
}

.container {
  box-sizing: border-box;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-content-width;
  right: 0;
  padding-left: remCalc(24px);
  padding-right: remCalc(24px);
  width: 100%;

  @include media-up(sm) {
    padding-left: remCalc(70px);
    padding-right: remCalc(70px);
  }

  &__narrow {
    @include media-up(sm) {
      padding: 0 remCalc(150px);
    }
  }

  &__medium {
    @include media-up(sm) {
      padding: 0 remCalc(110px);
    }
  }
}

.hr {
  border-radius: 10px;
  border: 2px solid #ba0c2f;
}

.disclaimer {
  background-color: $color-white;
  border: 2px #ff3131 solid;
  color: $color-black;
  font-size: 13px;
  line-height: 20px;
  padding: 0 1rem;
}

//CUSTOM STYLES FOR PAGES

//LOGIN PAGE
.login-screen {
  background: #f5f9fd;
  position: relative;
  min-height: calc(100vh - #{$nav-height});

  @include masthead-img('../images/photo-login-01.png');

  @include media-up(md) {
    &::after {
      max-width: 720px;
    }
  }
}

.login-wrapper,
.banner-wrapper {
  display: grid;

  @include media-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.login-image,
.banner-image {
  display: block;
  width: 100%;

  @include media-up(md) {
    display: none;
  }
}

.login-image {
  margin-bottom: remCalc(24px);
}

// HOME PAGE
.SLIDER-PLACEHOLDER {
  min-height: 400px;
  margin-bottom: remCalc(48px);
}

.home-page__hr {
  margin: 60px auto 10px auto;
  width: 110px;

  &--alt {
    margin: 0;
  }
}

.home-page__text-description {
  align-items: center;
  color: $font-color-primary;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.4px;
  padding: 70px;
  text-align: center;

  p {
    margin-bottom: remCalc(24px);
  }

  @include media-down(sm) {
    padding: 40px 0;
    font-size: 20px;
    line-height: 27px;
  }
}
.btn-row {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.btn-row button a {
  color: $color-white;
}

.btn-row button:hover {
  a {
    color: $color-primary-navy;
  }
}

.usaid-section__btn-row {
  background-color: $color-dark-blue;
  color: $color-white;
  // margin-top: 70px;
  margin-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;

  p {
    margin-bottom: 2rem;
  }

  button {
    background: $font-color-link;
    border-radius: 5px;

    a {
      color: $color-white;
    }
  }
}

.btn-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

// .home-page__text-description{
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// gap: 10px;
// }

// PARTNERS
.partners-page__header-container {
  width: 50%;

  @include media-down(sm) {
    width: 100%;
  }
}

.partners-page__banner-section {
  padding: 60px 0 60px 180px;
  background: #edf1f5;

  @include media-down(sm) {
    padding: 0;
  }
}

// OUR CONSULTANTS
.consultant-card-container {
  margin-top: remCalc(68px);
  max-height: remCalc(907px);
  overflow-y: scroll;
  flex-wrap: wrap;

  @include media-down(sm) {
    margin-top: remCalc(40px);
    max-height: remCalc(1212px);
    flex-wrap: nowrap;
  }
}
