@import '../../assets/styles/utility/variables';

.search-result {
  &__container {
    width: 100%;
  }

  &__title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
  }

  &__description {
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  &__tags-container {
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 12px;
  }
}
