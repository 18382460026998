@import '../../assets/styles/utility/variables';

.login-form {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: remCalc(96px);

    @include media-up(md) {
      margin-top: remCalc(100px);
      margin-right: remCalc(130px);
    }
  }

  &__title {
    padding: 0 0 12px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 64px;
    color: $color-primary-navy;
  }

  &__description {
    padding: 0 0 30px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  &__field-container {
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: column;
    width: 327px;

    @media only screen and (max-width: $mobile-screen) {
      width: 100%;
    }

    &--error {
      border-radius: 8px;
      border-width: 1px 1px 0 1px;
      width: 100%;
      background: #b70000;
    }
  }

  &__field {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
    padding: 16px 20px;
    background: $color-white;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;

    &--error {
      border: 2px solid #b70000;
      box-sizing: border-box;
    }

    &--error-text {
      padding: 8px 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: $color-primary-red;
    }
  }

  &__submit {
    border: none;
    border-radius: 11px;
    padding: 16px 0;
    width: 198px;
    background: #0057c8;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1428em;
    text-transform: uppercase;
    color: $color-white;

    @media only screen and (max-width: $mobile-screen) {
      width: 100%;
    }
  }
}

//ERROR HANDLING STYLES
// .login-form__field-container--error
// .login-form__field--error
// .login-form__field--error-text
