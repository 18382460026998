@import '../../assets/styles/utility/variables';

.slider-container {
  margin-bottom: remCalc(120px);
}

.slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  margin-top: remCalc(30px);
  min-height: 375px;
  position: relative;

  @include media-up(lg) {
    min-height: 580px;
  }
}

.slider-details {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.container.slider-navigation-container {
  @include media-down(lg) {
    padding: 0;
  }
}

.slider-navigation-wrapper {
  background: #00193a;
  color: $color-white;
  padding: remCalc(40px);
  width: 100%;
  .h0 {
    color: $color-white;
  }

  @include media-up(lg) {
    left: 0;
    padding: remCalc(45px) remCalc(25px);
    position: absolute;
    top: -340%;
    width: remCalc(380px);
    z-index: 2;
  }
}

.slider-thumb-wrapper {
  display: none;

  @include media-up(lg) {
    display: block;
    width: calc(100% - 450px);
  }
}

.nav-slide {
  text-align: center;
  padding: 0 remCalc(48px);

  // these typographical styles are unique to
  // the carousel, so scoping here
  h5 {
    @include fontSize(25px);
    font-weight: 400;
    line-height: 35px;
  }
}

.slider-navigation-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-navigation-button {
  width: auto;
  background: $font-color-link;
  color: $color-white;
  text-transform: uppercase;
  margin-top: 2rem;

  &:hover {
    background-color: $color-white;
    color: $color-dark-blue;
    a {
      color: $color-dark-blue;
    }
  }

  @include media-up(lg) {
    background: $font-color-link;
    border-radius: 5px;
    width: 100%;
  }
}

.slider-thumb-wrapper {
  height: remCalc(140px);
}

.thumb-slide {
  color: $color-white;
  text-align: center;
  width: 207px;

  h3 {
    @include fontSize(60px);
    font-weight: 600;
    line-height: 64px;
    color: $color-white;
  }

  h5 {
    @include fontSize(14px);
    font-weight: 400;
    line-height: 24px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: $color-white;
}
