@import './utility/variables';

//FONT FACES
@font-face {
  font-family: $font-family;
  src: url('../../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $font-family;
  src: url('../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $font-family;
  src: url('../../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font-family;
  src: url('../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-family;
  src: url('../../assets/fonts/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

//FONT DEFINITIONS
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

.primary {
  color: $font-color-primary;
}

.h0 {
  @include fontSize(44px);
  font-weight: 700;
  letter-spacing: 0px;
  line-height: remCalc(64px);

  @include media-up(md) {
    @include fontSize(64px);
    line-height: remCalc(96px);
  }
}

h1 {
  @include fontSize(56px);
  font-weight: 300;
  line-height: remCalc(84px);
  letter-spacing: 0px;

  @include media-down(sm) {
    @include fontSize(56px);
    line-height: remCalc(64px);
  }
}

h2,
.h2 {
  @include fontSize(44px);
  font-weight: 700;
  line-height: remCalc(64px);
  letter-spacing: 0px;

  @include media-down(sm) {
    @include fontSize(36px);
    line-height: remCalc(46.5px);
  }
}

.h1,
.h2 {
  &--dark {
    color: $font-color-primary;
  }
}

h3,
.h3 {
  @include fontSize(36px);
  font-weight: 700;
  line-height: remCalc(52px);
  letter-spacing: 0px;
}

h4 {
  @include fontSize(28px);
  font-weight: 600;
  line-height: remCalc(42px);
  letter-spacing: 0px;
}

h5,
.h5 {
  @include fontSize(24px);
  font-weight: 700;
  line-height: remCalc(36px);
  letter-spacing: 0.4000000059604645px;
}

h6 {
  @include fontSize(20px);
  font-weight: 700;
  line-height: remCalc(22px);
  letter-spacing: 0px;
}

p,
.p {
  @include fontSize(16px);
  font-weight: 300;
  line-height: remCalc(28px);
  letter-spacing: 0px;

  &.bold {
    font-weight: 700;
  }
}

a,
.button-link {
  @include fontSize(16px);
  color: $color-blue-4;
  font-weight: 600;
  letter-spacing: 0.4000000059604645px;
  line-height: remCalc(24px);
  text-decoration: none;
}

.ul {
  font-weight: 300;
  line-height: remCalc(28px);

  li {
    margin: remCalc(5px) 0 remCalc(5px) remCalc(16px);
  }
}

button,
.link-button {
  @include fontSize(14px);
  background-color: $color-primary-navy;
  border-radius: 11px;
  border: none;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.1428em;
  line-height: 19px;
  padding: 15px 20px;
}

.btn-primary,
.btn-secondary,
.btn-clear,
.btn-secondary a {
  border-radius: 11px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1428em;
  line-height: 19px;

  @include media-down(sm) {
    width: 100%;
  }
}

.btn-primary {
  background-color: $color-secondary-blue;
  border: 1px solid transparent;
  color: $color-white;

  &:hover {
    background-color: $color-secondary-light-blue;
    color: $color-primary-navy;
  }
  &:active {
    background-color: $color-primary-navy;
    color: $color-white;
  }
}

.btn-primary.cta {
  max-width: 500px;

  a {
    letter-spacing: 0.1428em;
  }
}

.btn-secondary {
  background-color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-dark-blue;
    a {
      color: $color-dark-blue;
    }
  }
}

.btn-clear {
  background-color: $color-white;
  border: 1px solid transparent;
  color: $color-primary-navy;

  &:hover {
    color: $color-primary-red;
    border: 1px solid $color-primary-red;
  }
  &:active {
    background-color: $color-primary-red;
    color: $color-white;
  }
}

// button styled like link
.button-link {
  background: none !important;
  border: none;
  cursor: pointer;
  padding: 0 !important;
}

.label {
  @include fontSize(8px);
  font-weight: 300;
  line-height: remCalc(14px);

  a {
    font-size: inherit;
    color: $color-teal;
    text-decoration: none;
    font-weight: bold;
  }
}

.italic {
  font-style: italic;
}

.semi-bold {
  font-weight: 600;
}

.line-height-short {
  line-height: remCalc(18px);
  margin-bottom: remCalc(6px);
  margin-top: remCalc(6px);
}
