@import '../../assets/styles/utility/variables';

.linkcard {
  &__container {
    border: 1px solid $color-blue;
    border-radius: 10px;
    background: #f7feff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;

    @include media-down(lg) {
      margin-bottom: remCalc(56px);
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__text-container {
    position: relative;
    padding: 45px 24px 8px 24px;
  }

  &__icon {
    background: #f7feff;
    position: absolute;
    top: -30px;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
  }

  &__description {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  &__link {
    padding: 0 24px 24px 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $font-color-link;
    text-decoration: none;

    &:hover {
      color: $color-blue-2;
    }
  }
}
