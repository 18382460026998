@import '../../assets/styles/utility/variables';

$image-shift: -90px;

.banner-alternate {
  background: $color-blue-2;
  color: $color-white;

  @media only screen and (max-width: $mobile-screen) {
    h2 {
      max-width: 270px;
    }
  }

  &__inner-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-width: calc(#{$max-content-width} - #{$image-shift});
    padding: 50px 160px 50px 0;
    position: relative;

    @media only screen and (max-width: $mobile-screen) {
      flex-direction: column;
      padding: 40px 24px;
      width: auto;
    }
  }

  &__image {
    height: 416px;
    left: $image-shift;
    position: relative;

    @media only screen and (max-width: $mobile-screen) {
      height: 202px;
      left: 0;
      margin: 46px 0;
      object-fit: cover;
      width: 327px;
    }
  }

  &__mobile-bg {
    display: none;
    @media only screen and (max-width: $mobile-screen) {
      background-color: $color-gray-3;
      display: block;
      height: 247px;
      left: 66px;
      position: absolute;
      top: 212px;
      width: 330px;
    }
  }

  &__hr {
    border-radius: 10px;
    border: 2px solid $color-primary-red;
    margin: 20px 0;
    width: 92px;
  }
}
