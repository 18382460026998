//FONTS
$font-family: 'Source Sans Pro';

//COLORS
$color-primary-navy: #002f6c;
$color-primary-red: #ba0c2f;
$color-secondary-blue: #0057c8;
$color-secondary-light-blue: #c2edff;

$color-ash: #edf1f5;
$color-black: #000000;
$color-blue-2: #02295b;
$color-blue-3: #13266c;
$color-blue-4: #004fb6;
$color-blue-light: #f6feff;
$color-blue: #0373b1;
$color-charcoal: #212721;
$color-dark-blue: #101c33;
$color-gray-2: #eaeaea;
$color-gray-3: #dae9f8;
$color-gray-4: #777777;
$color-gray-5: #555555;
$color-gray-6: #c4c4c4;
$color-gray: #5d6267;
$color-obsidian: #051b27;
$color-teal: #2687cd;
$color-white: #ffffff;
$font-color-link: #2469df;
$font-color-primary: #051432;

$color-tag-default: $color-secondary-light-blue;
$color-tag-region: #f3c2c2;
$color-tag-country: #d2e7d6;
$color-tag-sector: #f2f39e;
$color-tag-thematic: #bdd5f9;
$color-tag-technical: #e8b8ff;

$color-remove-button: #b70000;

//TRANSITIONS
// $transition-link: 0.3s cubic-bezier(0.09, 0.68, 0.35, 0.5);
// $transition-generic: 0.2s ease-in-out;

//CALCULATIONS
@function remCalc($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: remCalc($size);
}

//HEIGHTS
$nav-height: 80px;

//SCREEN SIZE
$max-content-width: 1440px;
$mobile-screen: 576px;

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin media-up($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-down($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//MASTHEAD
@mixin masthead-img($image) {
  @include media-up(md) {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      background: url($image);
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
